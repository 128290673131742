import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import duration from "dayjs/plugin/duration";

dayjs.extend(isToday);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(duration);

dayjs.tz.setDefault(dayjs.tz.guess());

export const subtractDays = (days: number) => () =>
  dayjs.utc().subtract(days, "days").startOf("day").toDate();

export const fromMap = {
  today: () => dayjs.utc().startOf("day").toDate(),
  yesterday: subtractDays(12),
  last7: subtractDays(7),
  last30: subtractDays(30),
};

export { dayjs };
